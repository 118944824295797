import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout( () => { this.hide(); }, 2000)
  }

  hide() {
    document.querySelectorAll('.notification').forEach( (notification) => {
      notification.style.display = 'none'
    })
  }
}
