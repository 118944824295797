// Turbo
import '@hotwired/turbo-rails'
Turbo.start()

// Styles
import "~/stylesheets/application.scss";

// Stimulus Controllers
import "~/controllers"

// Tippy.js (`yarn add tippy.js`)
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/scale.css';
window.tippy = tippy;

// Localtime
import LocalTime from "local-time"
LocalTime.start()

// Done
console.log('⚡️ KUY.io Kontrol™ loaded!')