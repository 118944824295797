import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.boundToggle = this.#toggle.bind(this);
    const burger = this.element.querySelector('.navbar-burger');
    burger.addEventListener('click', this.boundToggle);
  }

  disconnect() {
    const burger = this.element.querySelector('.navbar-burger');
    burger.removeEventListener('click', this.boundToggle);
  }

  #toggle(event) {
    const burger = this.element.querySelector('.navbar-burger');
    const menu = this.element.querySelector('.navbar-menu');

    burger.classList.toggle('is-active');
    menu.classList.toggle('is-active');
  }
}
