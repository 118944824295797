import { application } from "./application"

// Register controllers

import ChartController from './chart_controller'
application.register("chart", ChartController)

import CollapsibleController from './collapsible_controller'
application.register("collapsible", CollapsibleController)


import NavbarController from './navbar_controller'
application.register("navbar", NavbarController)

import NotificationsController from './notifications_controller'
application.register("notifications", NotificationsController)
