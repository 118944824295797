import { Controller } from '@hotwired/stimulus'

import 'chartjs-adapter-moment';
import annotationPlugin from 'chartjs-plugin-annotation';

import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
} from 'chart.js';

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  annotationPlugin
);

import merge from 'deepmerge'

export default class extends Controller {
  static targets = ['canvas']
  static values = {
    type: String,
    data: Object,
    options: Object
  }

  connect () {

    const element = this.hasCanvasTarget ? this.canvasTarget : this.element

    this.chart = new Chart(element.getContext('2d'), {
      type: this.typeValue || 'line',
      data: this.chartData,
      options: merge(this.defaultOptions, this.optionsValue)
    })

  }

  disconnect () {
    this.chart.destroy()
    this.chart = undefined
  }

  get chartData () {
    if (!this.hasDataValue) {
      console.warn('[stimulus-chartjs] You need to pass data as JSON to see the chart.')
    }

    return this.dataValue
  }

  get defaultOptions () {
    return {
      scales: {
        xAxis: {
          ticks: {
            maxRotation: 0,
            autoSkip: true,
            autoSkipPadding: 80,
            callback: function (value, index, values) {
              if (index === 0 || index % 2 === 0 || index === values.length - 1) {
                const label = value;
                return label;
              } else {
                return null;
              }
            },
          },
          bounds: 'ticks'
        }
      }
    }
  }
}
