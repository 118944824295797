import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "collapse", "arrow" ]

  click(event) {
    this.collapseTarget.classList.toggle("is-expanded")
    this.arrowTarget.classList.toggle("fa-chevron-right")
    this.arrowTarget.classList.toggle("fa-chevron-down")
  }
}